<template>
  <div class="room-detail" v-if="roomInfo" >
    <div class="head" @touchmove.prevent >
      <img
        class="back"
        src="../../assets/img/commoditySpike/back.png"
        alt=""
        @click="$goBack()"
      />
      <div class="title">{{roomInfo.name}}</div>
      <img
        class="share"
        src="../../assets/img/takeOutFood/share.png"
        alt=""
        @click="openShare()"
      />
      
    </div>
    <van-share-sheet
        v-model="showShare"
        title="立即分享给好友"
        cancel-text=""
        :options="options"
        @select="onSelect"
      />
    <div class="main">
      <div class="swiper-box">
        <van-swipe @change="onChange">
          <van-swipe-item v-for="item in roomInfo.imgUrl" :key="item">
                          <img :src="item"> 
          </van-swipe-item>
          <template #indicator>
            <div class="custom-indicator">{{ current + 1 }}/{{roomInfo.imgUrl.length}}</div>
          </template>
        </van-swipe>
      </div>
      <div class="room-name">{{roomInfo.name}}</div>
      <ul class="room-service">
        <li class="service-item">
          <img src="../../assets/img/bed.png" alt="" />
          <span>{{roomInfo.bedNum}}张大床{{roomInfo.bedSize}}米</span>
        </li>
        <li class="service-item">
          <img src="../../assets/roomImg/mto2.png" alt="" />
          <span style="line-height:0px;">{{roomInfo.measure}}m<sup>2</sup> </span>
        </li>
        <li class="service-item" v-if="roomInfo.floorUpper">
          <img src="../../assets/roomImg/floor.png" alt=""  />
          <span>{{roomInfo.floorUpper}}层<span v-if="roomInfo.floorLower">(共{{roomInfo.floorLower}})</span></span>
        </li>
        <li class="service-item" >
          <img src="../../assets/roomImg/window.png" alt="" />
          <span>{{roomInfo.haveWindow==='1'?"有窗":"无窗"}}</span>
        </li>
        <li class="service-item" v-if="roomInfo.haveWifi==='1'">
          <img src="../../assets/roomImg/wifi.png" alt="" />
          <span>WIFI</span>
        </li>
         <li class="service-item" v-if="roomInfo.haveBroadband==='1'">
          <img src="../../assets/roomImg/kuandai.png" alt="" />
          <span>有线宽带</span>
        </li>
        <li class="service-item">
          <img src="../../assets/roomImg/smoke.png" alt="" />
          <span>{{roomInfo.banSmoke==='1'?"禁烟":"部分禁烟"}}</span>
        </li>

        <li class="service-item" >
          <img src="../../assets/roomImg/poepleIcon.png" alt="" />
          <span>{{roomInfo.occupancy}}人住</span>
        </li>
        <li class="service-item" >
          <img src="../../assets/roomImg/food.png" alt="" />
          <span>{{roomInfo.haveBreakfast==='1'?"有早餐":"无早餐"}}</span>
        </li>
        
      </ul>
      <ul class="additional-services" v-if="roomInfo.costPolicy||roomInfo.amenities||roomInfo.bathroomConfig||roomInfo.foodDrink||roomInfo.foodDrink ">
        <li class="additional-item" v-if="roomInfo.costPolicy">
          <div class="title">费用政策</div>
          <div class="item-box" >
            {{roomInfo.costPolicy.replace(" ",'')}}
           
          </div>
        </li>
        <li class="additional-item" v-if="roomInfo.amenities">
          <div class="title">便利设施</div>
          <div class="item-box">
            {{roomInfo.amenities}}
          
          </div>
        </li>
         <li class="additional-item" v-if="roomInfo.bathroomConfig">
          <div class="title">浴室配套</div>
          <div class="item-box">
            {{roomInfo.bathroomConfig}}
           
          </div>
        </li>
        <li class="additional-item" v-if="roomInfo.foodDrink">
          <div class="title">食品饮品</div>
          <div class="item-box">
            {{roomInfo.foodDrink}}
            
          </div>
        </li>
      </ul>

        
      <ul class="service"  style="background-color: white;">
        <div class="title-tips">政策服务</div>
         
      <li v-if="roomInfo.fastConfirm" >
        <div class="subtitle">快速确认</div>
        <div div class="subtitle-info"><span>{{roomInfo.fastConfirm}}</span></div>
      </li>
      <li v-if="roomInfo.cancelPolicy">
        <div class="subtitle">取消政策</div>
          <p>限时取消</p>
      <div class="subtitle-info"><span>{{roomInfo.cancelPolicy}}</span></div>
      </li>
      <li v-if="roomInfo.otherPolicy">
        <div class="subtitle">其他政策</div>
        <div class="subtitle-info"><span>{{roomInfo.otherPolicy}}</span></div>
      </li>
      </ul>
    </div>
    <div class="footer" @touchmove.prevent>
      <div class="footer-box">
        <div class="call-phone">
            <a :href="'tel:'+roomInfo.phone">
            <img src="../../assets/img/kefu.png" alt="">
            <span>电话咨询 </span>
            </a>
        </div>
        <div class="btns">
            <div class="btn1">
                ￥
                <div class="total">{{roomInfo.price}}</div>
                <div class="orginactive">(均价) <span> ￥{{roomInfo.marketPrice}}</span></div>
                
            </div>
            <div class="btn2" @click="addOrder()">预定</div>
        </div>
        </div>
    </div>
  </div>
</template>

<script>
import { roomData} from '../../api/roomDetail/index'
export default {
  name:'NOkeep',
  data() {
    return {
      current: 0,
      showShare: false,
      roomInfo:null,
      week:['周一',"周二",'周三',"周四",'周五','周六',"周日"],
      options: [
        [
           { name: '微信', icon: 'wechat',index:0 },
          { name: '朋友圈', icon: 'wechat-moments', index:1 },
          { name: 'QQ', icon: 'qq' ,index:2},
        ],
       
      ],
    };
  },
  methods: {
    openShare() {
      this.showShare = true;
    },
    onChange(index) {
      this.current = index;
    },
    addOrder(){
      this.$router.push({
        path:"/confirmOrder",
       query:{
          id:this.roomInfo.id,
          endTime:this.$route.query.endTime,
          startTime:this.$route.query.startTime,
          dayNum:this.$route.query.dayNum,
          type:this.$route.query.type,
          roomNum:this.$route.query.roomNum
       }
      })
    },
    onSelect(index){
       let obj={
      title:this.roomInfo.name,
      picUrl:this.roomInfo.picUrl|| "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnge075b8c61cca1d61d6fd515ef1b8fe29671548b08ff1d214b42b6e4dded6c95e",
      platformId:index.index,
      openUrl:'',
      content:this.roomInfo.sellingPoints
      }
    this.shareInfo(obj)
  },
  },
  created(){
    this.setRem()
    try{ 
            this.$delete(this.$route.query, 'aaa');

    }catch{ 
          //
    }
    // this.$store.commit('setSession', this.$route.query.session)
          if(navigator.userAgent.indexOf('万民')==-1){
            this.openApp()
          return
      }

if(this.$route.query.session){ 
  this.$store.commit('setSession',this.$route.query.session)
}


  let time = this.$route.query.startTime.replace(/年|月|日/ig,'')
  let date = new Date()
  console.log(time.slice(0,8)-(date.getFullYear()+'' +(date.getMonth()+1) + '' + date.getDate())<0,5555);

  console.log((date.getFullYear()+'' +(date.getMonth()+1) + '' + date.getDate()),5555);
  if(time.slice(0,8)-(date.getFullYear()+'' +(date.getMonth()+1) + '' + date.getDate())<0){ 
    time = new Date()
      this.$route.query.startTime = time.getFullYear()  + '年' + ( time.getMonth() + 1 )+'月' + time.getDate() + '日'+`   ` + this.week[time.getDay()] 
      time = Date.now()+1000*24*60*60
      time = new Date(time)
      this.$route.query.endTime = time.getFullYear() + '年' + ( time.getMonth() + 1 ) + '月' + time.getDate() + '日'+`   ` + this.week[time.getDay()] 

       time = null
       date = null

  }
  
    // this.$store.commit('setSession',this.$route.query.session)
    // console.log(this.$route.query.id);
    roomData(this.$route.query.id).then(res=>{
      if(res.data.code===0){
       
        this.roomInfo=res.data.data
         console.log(this.roomInfo);
      }
    })
    
  },
  mounted() {
    this.getUser()
    window.addEventListener("resize", this.setRem);
  },
};
</script>



<style lang="less" scoped>
.room-detail::-webkit-scrollbar{
    display: none;
}
.room-detail {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  height: 100%;
  width: 100%;
  background-color:white;
  display: flex;
  flex-direction: column;
  .head {
  
   
   
    padding: .266667rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.013333rem solid #ccc;
    .title {
      margin: auto;
      font-size: 0.453333rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      padding-left: 0.16rem;
    }
    .back {
      width: 0.666667rem;
      height: 0.64rem;
      margin-left: 0.266667rem;
    }
    .share {
      width: 0.64rem;
      height: 0.64rem;
      margin-right: 0.426667rem;
    }
  }
  .van-popup{
    z-index: 200;
  }
  .main::-webkit-scrollbar {
    display: none;
  }
  .main {
    box-sizing: border-box;
    overflow: auto;
    flex: 1;
    .swiper-box {
      width: 100%;
      height: 6.4rem;
      .van-swipe {
        height: 100%;
        .van-swipe-item {
          img{
              width: 100%;
              height: 100%;
          }
        }
      }
      .custom-indicator {
        position: absolute;
        right:.133333rem;
        bottom: .133333rem;
        padding: .026667rem .066667rem;
        font-size: 12px;
        background: black;
        width: .906667rem;
        height: .586667rem;
        color: white;
        border-radius: .44rem;
        opacity: 0.3;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .room-name {
      padding: .533333rem 0.426667rem ;
      font-size: 0.48rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 0.666667rem;
      background-color: white;
    }
    .room-service {
      padding: 0 0.426667rem .533333rem;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      background-color: white;
      border-bottom: .266667rem solid #f6f6f6;
      .service-item {
        width: 3rem;
        display: flex;
        align-items: center;
        margin-bottom: 0.133333rem;
        font-size: 0.32rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        img {
          width: 0.453333rem;
          height: 0.453333rem;
          // border: 0.013333rem dashed #ccc;
          margin-right: 0.08rem;
        }
      }
    }
    .additional-services {
      padding: .533333rem 0.426667rem;
      margin-bottom: 1.333333rem;
      background-color: white;
      border-bottom: .266667rem solid #f6f6f6;
      .additional-item {
        display: flex;
        font-size: 0.32rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        margin-bottom: .4rem;
        .title {
          margin-right: 0.266667rem;
          color: #aaa;
          white-space: nowrap;
        }
        .item-box{
          display: block;
          color:black;
            font-weight: 500;
            font-family: PingFangSC-Regular, PingFang SC;
            white-space: pre-wrap;
            text-align: left;
        }
      }
    }
    .service{
      padding: .533333rem 0.426667rem;
     

     color: #ccc;
    .title-tips {
      font-size: 0.373333rem;
      font-weight: 600;
      font-family: PingFangSC-Regular, PingFang SC;
      margin-bottom: 0.4rem;
        color: #666;
    }
    .subtitle{
        color: #666;
     
        margin-bottom: .4rem;
    }
    p{
        color: #666;
    }
    .subtitle-info{
        font-size: .32rem;
        margin-bottom: 0.4rem;
       white-space: pre-wrap;
        span{
            color: #ccc;
        }
    }
    }
  }
  .footer{
  
     width: 100%;
     background-color: white;
   
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
     
    
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    .footer-box{
      position: relative;
      z-index: 100;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: .266667rem .426667rem .266667rem .24rem;
      .call-phone{
       
          a{
          display: flex;
          flex-direction: column;
          align-items: center;
          img{
              width: .64rem;
              height: .64rem;
              margin-bottom: .133333rem;
          }
          span{
              display: block;
              font-size: .32rem;
              transform: scale(0.83);
              color: black;
          }
           }
      }
      .btns{
          display: flex;
          align-items: center;
         justify-content: flex-end;
          height: 1.066667rem;
          overflow: hidden;
          width:8rem;
          color: #999999FF;
          font-size: .32rem;
          
          .btn1{
              width: 5.68rem;
              height: 100%;
              background-color: #313131FF;
              color: white;
              display: flex;
              align-items: flex-end;
          
              border-radius: .266667rem 0 0 .266667rem;
              padding-left: .133333rem;
              padding-bottom: .133333rem;
              box-sizing: border-box;
              .total{
                  color: white;
                  font-size: .693333rem;
                  height:.86rem;
              }
              div{
                  margin-top: .266667rem;
                   color: #999999FF;
              }
               span{
                      text-decoration: line-through;
                  }
          }
          .btn2{
             border-radius:0 .266667rem  .266667rem 0;

              width: 1.92rem;
              height: 100%;
             background-image: linear-gradient(to right ,#FF7200,#FF3C00);
             background-size: 100% 100%;
             background-repeat: no-repeat;
             font-size: .373333rem;
             color: white;
             display: flex;
             align-items: center;
             justify-content: center;
           

          }
      }
  }}
}
</style>